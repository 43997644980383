













import AdminUserHeader from "@/components/Admin/Dashboard/User/Header.vue";
import AdminUserList from "@/components/Admin/Dashboard/User/List.vue";
import AdminUserNew from "@/components/Admin/Dashboard/User/New.vue";
import BaseWrapper from "@/components/Base/BaseWrapper.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
    components: {
        BaseWrapper,
        AdminUserList,
        AdminUserHeader,
        AdminUserNew,
    },
    metaInfo() {
        const title = "User";

        return {
            title: title,
        };
    },
})
export default class AppAdminUsers extends Vue {
    public showDialog: boolean = false;

    get users() {
        return this.$store.getters["users/sortedUsers"];
    }

    async mounted() {
        const users = await this.$store.dispatch("users/getAllUser");
    }

    createUser() {
        this.showDialog = true;
    }
}
