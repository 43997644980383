
















import { Component, Prop, Vue } from "vue-property-decorator";
import AdminUserListItem from "./ListItem.vue";

@Component({
    components: {
        AdminUserListItem,
    },
})
export default class AdminUserList extends Vue {
    @Prop() users!: any[];
}
